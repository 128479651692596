import { AxiosInstance } from 'axios';
import AxiosRepository from './axios.repository';
import { IGlobalRepository } from '@common/interfaces';

class GlobalRepository extends AxiosRepository implements IGlobalRepository {
  constructor(axiosClient: AxiosInstance) {
    super(axiosClient);
  }

  async getCurrentUser() {
    const { data } = await this.get(`/accounts/me/`);
    return data;
  }

  async getWaffles() {
    const { data } = await this.get(`/waffle_status`);
    return data;
  }
}

export default GlobalRepository;
