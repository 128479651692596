import { Routes, Route, Navigate } from 'react-router-dom';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { useAuth } from '@unbooking/ui-auth';
import { CallbackPage, CMSPage, LoginPage } from '@pages';
import { FacilityProvider, GlobalProvider, RepositoryProvider } from '@context';
import routes from './routes';
import { Footer, Header } from '@components';
import { handleError } from '@common/utils';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2, // Note: hook for getting data in token refresh process
    },
  },
  queryCache: new QueryCache({
    onError: (err) => handleError(err),
  }),
  mutationCache: new MutationCache({
    onError: (err) => handleError(err),
  }),
});

function App() {
  const { isAuthenticated } = useAuth();

  return (
    <QueryClientProvider client={queryClient}>
      <RepositoryProvider>
        <FacilityProvider>
          <GlobalProvider>
            {isAuthenticated ? <Header /> : null}
            <Routes>
              {isAuthenticated ? (
                <>
                  {/* Note: the home page is missing in App, we should set it on CMS side! */}
                  <Route path='/' element={<Navigate to={'/home'} />} />
                  {routes.map(({ Component, path }, i) => (
                    <Route path={path} key={i} element={<Component />} />
                  ))}
                  <Route path='callback/*' element={<CallbackPage />} />
                  <Route path='*' element={<CMSPage />} />
                </>
              ) : (
                <>
                  <Route index element={<LoginPage />} />
                  <Route path='callback/*' element={<CallbackPage />} />
                  <Route path='*' element={<Navigate to='/' />} />
                </>
              )}
            </Routes>
            {isAuthenticated ? <Footer /> : null}
          </GlobalProvider>
        </FacilityProvider>
      </RepositoryProvider>
    </QueryClientProvider>
  );
}

export default App;
