import { Dispatch, FC, SetStateAction, useState } from 'react';
import {
  Button,
  DefaultField,
  Dropdown,
  Empty,
  Main,
  NumberField,
  Page,
  Table,
  Text,
  TextArea,
  TextField,
  Tip,
  TotalCostsBox,
} from '@components';
import {
  ArrowLeftIcon,
  CloseRoundIcon,
  CompletedIcon,
  DocIcon,
  EditIcon,
  PlusIcon,
  SuccessIcon,
  SyncIcon,
  TrashIcon,
} from '@assets/svg';
import { URLS } from '@common/constants';
import {
  IDraftInvoice,
  IDropdownOption,
  IFocalPoint,
  IInvoiceOptions,
  IInvoiceOtherCost,
  IInvoiceOtherCostPostData,
  IJobcard,
  IJobcardDetails,
  ILabourCost,
  IPartsCost,
  JobcardSyncData,
  Nullable,
  PaginatedResponse,
  User,
} from '@common/interfaces';
import { useMutation, useQuery } from 'react-query';
import { useFacilityContext, useRepository } from '@context';
import { ColumnType } from 'antd/lib/table';
import { Table as AntdTable } from 'antd';
import { formatMoneyStringToNumber, formatToMoneyString } from '@common/utils';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { useDownloadFile } from '@common/hooks';
import { GroupBase, OptionsOrGroups } from 'react-select';
import * as Yup from 'yup';
import { Form, FormState, useFormState } from 'informed';
import { v4 as uuid } from 'uuid';
import css from './styles.module.scss';

interface JobcardOption extends IDropdownOption {
  customer: string;
}

interface AddOtherCostMutateData {
  payload: IInvoiceOtherCostPostData;
  invoiceId: string;
}

interface IOtherCostForm {
  idx: number;
  item: Partial<IInvoiceOtherCost>;
  setItems: Dispatch<SetStateAction<Partial<IInvoiceOtherCost>[]>>;
}

const labourCostSummary = (pageData: readonly ILabourCost[]) => {
  const costTotal = pageData.reduce((acc, { total_labour_cost = 0 }) => {
    return acc + total_labour_cost;
  }, 0);

  const hoursTotal = pageData.reduce((acc, { hours = 0 }) => {
    return acc + hours;
  }, 0);

  return (
    <>
      <AntdTable.Summary.Row>
        <AntdTable.Summary.Cell index={1}>Total</AntdTable.Summary.Cell>
        <AntdTable.Summary.Cell index={2}></AntdTable.Summary.Cell>
        <AntdTable.Summary.Cell index={3}>{hoursTotal}</AntdTable.Summary.Cell>
        <AntdTable.Summary.Cell index={4}></AntdTable.Summary.Cell>
        <AntdTable.Summary.Cell index={5}>{formatToMoneyString(costTotal)}</AntdTable.Summary.Cell>
      </AntdTable.Summary.Row>
    </>
  );
};

const defaultRender = (item: string) => item || 'n.d.';

const labourCostColumns: ColumnType<ILabourCost>[] = [
  {
    dataIndex: 'number',
    key: 'number',
    title: '#',
    render: (_1, _2, idx) => idx + 1,
  },
  {
    dataIndex: 'job_description',
    key: 'job_description',
    title: 'Job description',
    render: defaultRender,
  },
  {
    dataIndex: 'hours',
    key: 'hours',
    title: 'Labour hours',
    render: defaultRender,
  },
  {
    dataIndex: 'cost_labour',
    key: 'cost_labour',
    title: 'Rate (USD)',
    render: (cost_labour) => formatToMoneyString(cost_labour),
  },
  {
    dataIndex: 'total_labour_cost',
    key: 'total_labour_cost',
    title: 'Total labour cost (USD)',
    render: (total_labour_cost) => formatToMoneyString(total_labour_cost),
  },
];

const partsCostSummary = (pageData: readonly IPartsCost[]) => {
  const costTotal = pageData.reduce((acc, { parts_total_cost = 0 }) => {
    return acc + parts_total_cost;
  }, 0);

  const unitsTotal = pageData.reduce((acc, { parts_quantity = 0 }) => {
    return acc + parts_quantity;
  }, 0);

  return (
    <>
      <AntdTable.Summary.Row>
        <AntdTable.Summary.Cell index={1}>Total</AntdTable.Summary.Cell>
        <AntdTable.Summary.Cell index={2}></AntdTable.Summary.Cell>
        <AntdTable.Summary.Cell index={3}></AntdTable.Summary.Cell>
        <AntdTable.Summary.Cell index={4}>{unitsTotal}</AntdTable.Summary.Cell>
        <AntdTable.Summary.Cell index={5}></AntdTable.Summary.Cell>
        <AntdTable.Summary.Cell index={6}>{formatToMoneyString(costTotal)}</AntdTable.Summary.Cell>
      </AntdTable.Summary.Row>
    </>
  );
};

const partsCostColumns: ColumnType<IPartsCost>[] = [
  {
    dataIndex: 'number',
    key: 'number',
    title: '#',
    render: (_1, _2, idx) => idx + 1,
  },
  {
    dataIndex: 'part_number',
    key: 'part_number',
    title: 'Part number',
    render: defaultRender,
  },
  {
    dataIndex: 'description',
    key: 'description',
    title: 'Part description',
    render: defaultRender,
  },
  {
    dataIndex: 'parts_quantity',
    key: 'parts_quantity',
    title: 'Quantity',
    render: defaultRender,
  },
  {
    dataIndex: 'parts_cost',
    key: 'parts_cost',
    title: 'Unit cost (USD)',
    render: (cost) => formatToMoneyString(cost),
  },
  {
    dataIndex: 'parts_total_cost',
    key: 'parts_total_cost',
    title: 'Total parts cost (USD)',
    render: (parts_total_cost) => formatToMoneyString(parts_total_cost),
  },
];

const mapJobcards = (jobcards?: IJobcard[]) => {
  if (!jobcards) return [];
  return jobcards.map(({ customer, job_number, pk }) => ({
    label: job_number,
    value: pk,
    customer,
  }));
};

const yupSchema = Yup.object().shape({
  description: Yup.string().required(),
  cost: Yup.string().required(),
});

const OtherCostForm: FC<IOtherCostForm> = ({ idx, item, setItems }) => {
  const { uuid, description, cost } = item as IInvoiceOtherCost;

  const [isEdit, setIsEdit] = useState(true);

  const onSubmit = (data: FormState) => {
    setItems((prevArray) => [...prevArray].map((item) => (item.uuid === uuid ? { ...item, ...data.values } : item)));
    setIsEdit(false);
  };

  const discardItem = () => {
    setIsEdit(false);
    if (!description && !cost) {
      setItems((prevArray) => [...prevArray].filter((item) => item.uuid !== uuid));
    }
  };

  const deleteItem = () => {
    setItems((prevArray) => [...prevArray].filter((item) => item.uuid !== uuid));
  };

  const ButtonConfirm = () => {
    const { values } = useFormState();
    const { cost, description } = values as unknown as Partial<IInvoiceOtherCost>;
    const isNotValid = cost === undefined || !description;

    return (
      <Tip isVisible={isNotValid} text='All fields should be filled.'>
        <Button
          text='Confirm'
          iconL={<CompletedIcon />}
          type='submit'
          variant='text'
          className={css.buttonSuccess}
          disabled={isNotValid}
        />
      </Tip>
    );
  };

  return (
    <Form
      yupSchema={yupSchema}
      initialValues={item as unknown as Record<string, unknown>}
      className={`${css.row} ${isEdit ? css.editable : ''}`}
      onSubmit={onSubmit}
      key={isEdit.toString()}
    >
      <div className={css.td}>{idx + 1}</div>
      <div className={css.td}>{isEdit ? <TextField name='description' /> : description}</div>
      <div className={css.td}>{isEdit ? <NumberField name='cost' allowDecimal /> : formatToMoneyString(cost)}</div>
      <div className={css.td}>
        <div className={css.actions}>
          {isEdit ? (
            <>
              <Button text='Discard' iconL={<CloseRoundIcon />} onClick={discardItem} variant='text' />
              <ButtonConfirm />
            </>
          ) : (
            <>
              <Button
                text='Edit'
                iconL={<EditIcon />}
                onClick={() => setIsEdit(true)}
                variant='text'
                className={css.buttonEdit}
              />
              <Button text='Delete' iconL={<TrashIcon />} onClick={deleteItem} variant='text' />
            </>
          )}
        </div>
      </div>
    </Form>
  );
};

const GenerateInvoicePage: FC = () => {
  const navigate = useNavigate();
  const { facilityId, facility } = useFacilityContext();
  const { jobcardRepository, invoiceRepository, globalRepository, facilityRepository } = useRepository();

  const { country_name } = facility;

  const [jobcard, setJobcard] = useState<Nullable<JobcardOption>>(null);

  const [otherItems, setOtherItems] = useState<Partial<IInvoiceOtherCost>[]>([]);
  const [workshopMarginValue, setWorkshopMarginValue] = useState(0);
  const [mcrMarginIncluded, setMcrMarginIncluded] = useState(true);
  const [approver, setApprover] = useState<Nullable<IDropdownOption>>(null);
  const [remarks, setRemarks] = useState('');
  const [slaNumber, setSlaNumber] = useState('');

  const { data: user, isLoading: userLoading } = useQuery<User>('user', () => globalRepository.getCurrentUser());

  const { data: focalPoints, isLoading: isFocalPointsLoading } = useQuery<PaginatedResponse<IFocalPoint>>(
    'focal-points',
    () => facilityRepository.getFocalPoints(facilityId)
  );

  const loadJobcardOptions = async (search: string, loadedOptions: OptionsOrGroups<unknown, GroupBase<unknown>>) => {
    const { results, next } = await jobcardRepository.getJobcards(facilityId, {
      limit: 10,
      search,
      by_facility: facilityId,
      offset: loadedOptions.length,
      is_pending: true,
    });

    return {
      options: mapJobcards(results),
      hasMore: Boolean(next),
    };
  };

  const { data: jobcardDetails, isLoading: isJobcardLoading } = useQuery<IJobcardDetails>(
    ['jobcard', jobcard],
    () => jobcardRepository.getJobcard(facilityId, jobcard?.value || ''),
    {
      enabled: Boolean(jobcard),
    }
  );

  const { mutate: generateInvoicePdf, isLoading: isGenerationPdfInProgress } = useMutation(
    (invoiceId: string) => invoiceRepository.generateInvoicePdf(facilityId, invoiceId),
    {
      onSuccess: ({ pdf, invoice_number }) => {
        toast.success('Invoice was generated successfully');
        useDownloadFile(pdf, `${invoice_number}.pdf`);
        setTimeout(() => navigate(URLS.DOCUMENTS), 1000);
      },
    }
  );

  const { mutate: addOtherCost, isLoading: isAddingOtherCostInProgress } = useMutation((data: AddOtherCostMutateData) =>
    invoiceRepository.addOtherCost(facilityId, data.invoiceId, data.payload)
  );

  const { mutate: createInvoice, isLoading: isCreationInProgress } = useMutation(
    (data: IDraftInvoice) => invoiceRepository.createInvoice(facilityId, data),
    {
      onSuccess: ({ uuid }) => {
        const promises = otherItems
          .filter(({ cost, description }) => cost && description)
          .map((item) => {
            const { description, cost } = item as IInvoiceOtherCostPostData;
            const payload = {
              description,
              cost: formatMoneyStringToNumber(cost),
            };
            return addOtherCost({ payload, invoiceId: uuid });
          });
        Promise.all(promises).then(() => generateInvoicePdf(uuid));
      },
    }
  );

  const { mutate: syncJobcardsData, isLoading: isJobcardSyncInProgress } = useMutation(
    (params: JobcardSyncData) => jobcardRepository.syncJobcardsData(facilityId, params),
    {
      onSuccess: ({ detail }) => {
        toast.success(detail);
      },
    }
  );

  const { data: invoiceOptions, isLoading: isOptionsLoading } = useQuery<IInvoiceOptions>('invoice-options', () =>
    invoiceRepository.getOptions(facilityId)
  );

  const { labour_cost_breakdown = [], parts_cost_breakdown = [] } = jobcardDetails || {};

  const handleGenerate = () => {
    createInvoice({
      approved_by: approver?.value || '',
      customer: jobcard?.customer || '',
      is_mcr_needed: mcrMarginIncluded,
      jobcard: jobcard?.value || '',
      pdf_remarks: remarks,
      sla_ref: slaNumber,
      workshop_margin_percentage: workshopMarginValue,
    });
  };

  const approversOptions = focalPoints
    ? focalPoints.results.map(({ uuid, first_name, last_name }) => ({
        label: `${first_name} ${last_name}`,
        value: uuid,
      }))
    : [];

  const mcrMarginValue = invoiceOptions?.variables.mcr_fee_percentage || 0;

  const totalOtherCosts = otherItems.reduce((acc, { cost = 0 }) => {
    return acc + formatMoneyStringToNumber(cost);
  }, 0);

  const totalLineItems =
    labour_cost_breakdown.reduce((acc, { total_labour_cost = 0 }) => {
      return acc + total_labour_cost;
    }, 0) +
    parts_cost_breakdown.reduce((acc, { parts_total_cost = 0 }) => {
      return acc + parts_total_cost;
    }, 0) +
    totalOtherCosts;

  const isGeneratingPossible = jobcard && approver && slaNumber;

  const addNewItem = () => setOtherItems((prevArray) => [...prevArray, { uuid: `draft${uuid()}` }]);

  const loading =
    userLoading ||
    isOptionsLoading ||
    isFocalPointsLoading ||
    isCreationInProgress ||
    isGenerationPdfInProgress ||
    isAddingOtherCostInProgress ||
    isJobcardLoading;

  return (
    <Main loading={loading}>
      <Page>
        <Button text='Back to all documents' variant='text' iconL={<ArrowLeftIcon />} link={URLS.DOCUMENTS} />
        <div className={css.title}>New invoice</div>
        <div className={css.note}>Easily generate invoice for your jobcards</div>
        <div className={css.top}>
          <Dropdown
            className={css.jobcardDropdown}
            value={jobcard}
            isSearchable
            loadOptions={loadJobcardOptions}
            onChange={setJobcard}
            placeholder='Choose jobcard...'
          />
          {jobcard ? (
            <Button
              text='Sync with FMS'
              iconR={<SyncIcon />}
              className={css.button}
              variant='text'
              disabled={isJobcardSyncInProgress}
              onClick={() => syncJobcardsData({ job_number: jobcard.label })}
            />
          ) : null}
          {jobcard ? (
            <div className={css.address}>
              <div className={css.label}>Customer details</div>
              {jobcard.customer}, {country_name}
            </div>
          ) : null}
        </div>
        {jobcard ? (
          <>
            <div className={css.subtitle}>Labour cost breakdown</div>
            <Table
              className={css.table}
              columns={labourCostColumns}
              data={labour_cost_breakdown}
              summary={labourCostSummary}
            />
            <div className={css.subtitle}>Spare parts cost breakdown</div>
            <Table
              className={css.table}
              columns={partsCostColumns}
              data={parts_cost_breakdown}
              summary={partsCostSummary}
            />
            <div className={css.subtitle}>Other costs breakdown</div>
            <Button
              text='Add new other cost'
              variant='text'
              iconR={<PlusIcon />}
              onClick={addNewItem}
              className={css.buttonAdd}
            />
            <div className={css.tableWrapper}>
              <div className={css.otherCostTable}>
                <div className={css.thead}>
                  <div className={css.th}>#</div>
                  <div className={css.th}>Description</div>
                  <div className={css.th}>Total parts cost (USD)</div>
                  <div className={css.th}>Actions</div>
                </div>
                <div className={css.tbody}>
                  {otherItems.length > 0 ? (
                    otherItems.map((item, idx) => (
                      <OtherCostForm item={item} idx={idx} key={item.uuid} setItems={setOtherItems} />
                    ))
                  ) : (
                    <Empty text='No line items' />
                  )}
                </div>
              </div>
            </div>
            <div className={css.tfoot}>
              <div className={css.td}>Total</div>
              <div className={css.td} />
              <div className={css.td}>{formatToMoneyString(totalOtherCosts)}</div>
              <div className={css.td} />
            </div>
          </>
        ) : (
          <div className={css.empty}>
            <DocIcon />
            Select a jobcard to display its associated costs here.
          </div>
        )}
        <div className={css.content}>
          <div className={css.pfiInfo}>
            <div className={css.field}>
              <div className={css.label}>SLA ref</div>
              <Text value={slaNumber} onChange={setSlaNumber} />
            </div>
            <div className={css.field}>
              <div className={css.label}>Prepared by</div>
              <DefaultField text={user?.full_name} />
            </div>
            <div className={css.field}>
              <div className={css.label}>Approved by</div>
              <Dropdown value={approver} options={approversOptions} onChange={setApprover} />
            </div>
            <div className={css.field}>
              <div className={css.label}>
                PDF remarks <span>(optional)</span>
              </div>
              <TextArea value={remarks} onChange={setRemarks} />
            </div>
          </div>
          <TotalCostsBox
            mcrMarginValue={mcrMarginValue}
            setWorkshopMarginValue={setWorkshopMarginValue}
            totalLineItems={totalLineItems}
            workshopMarginValue={workshopMarginValue}
            mcrMarginIncluded={mcrMarginIncluded}
            setMcrMarginIncluded={setMcrMarginIncluded}
          />
        </div>
        <Tip
          isVisible={!isGeneratingPossible}
          text='At least one selected jobcard, approver and SLA ref should be added'
        >
          <Button
            text='Generate invoice'
            iconR={<SuccessIcon />}
            variant='forest'
            onClick={handleGenerate}
            disabled={!isGeneratingPossible}
          />
        </Tip>
      </Page>
    </Main>
  );
};

export default GenerateInvoicePage;
