import { FC } from 'react';
import { IAppointmentType, IBookingDetails, Nullable } from '@common/interfaces';
import { differenceInMinutes, format, isPast } from 'date-fns';
import { DEFAULT_USER_DATE_TIME_FORMAT, LINKS } from '@common/constants';
import { CheckInIcon, CheckOutIcon, EditIcon, SuccessIcon, WrenchPlannerIcon, WrenchScrewIcon } from '@assets/svg';
import { getLocalDate } from '@common/utils';
import Box from '../Box';
import { Button } from '@components';
import css from './styles.module.scss';

interface ITimeLineBlock {
  bookingDetails: IBookingDetails;
}

interface IPartTime {
  icon: JSX.Element;
  isFinished: boolean;
  label: string;
  value?: string;
  valueSec?: string;
  editLink?: string;
}

const getDuration = (startDate: Nullable<Date>, finishDate: Date) => {
  if (!startDate) return null;
  const minutes = differenceInMinutes(finishDate, startDate);
  if (minutes < 0) return null;
  if (minutes < 1440) return 1;
  return Math.ceil(minutes / 1440);
};

const PartTime: FC<IPartTime> = ({ icon, isFinished, label, value, valueSec, editLink }) => {
  return (
    <div className={`${css.part} ${isFinished ? css.finished : ''}`}>
      <div className={css.time}>
        <div className={css.line}></div>
        {isFinished ? <SuccessIcon className={css.successIcon} /> : <div className={css.round}> </div>}
      </div>
      <div className={css.info}>
        <div className={css.icon}>{icon}</div>
        <div className={css.text}>
          <div className={css.label}>{label}</div>
          {value ? <div>{value}</div> : null}
          {valueSec ? <div>{valueSec}</div> : null}
        </div>
      </div>
      {editLink ? (
        <Button text='Edit' link={editLink} variant='text' iconL={<EditIcon />} className={css.button} />
      ) : null}
    </div>
  );
};

const TimeLineBlock: FC<ITimeLineBlock> = ({ bookingDetails }) => {
  const { jobcard, appointments, uuid: bookingId, approved_by } = bookingDetails;

  const checkInDate = appointments.find((a) => a.appointment_type === IAppointmentType.CHECK_IN)?.from_to_datetime_range
    .from;
  const checkInFormattedDate = checkInDate ? getLocalDate(checkInDate) : null;

  const checkOutDate = appointments.find((a) => a.appointment_type === IAppointmentType.CHECK_OUT)
    ?.from_to_datetime_range.from;
  const checkOutFormattedDate = checkOutDate ? getLocalDate(checkOutDate) : null;

  const workStartDate = jobcard?.job_date ? getLocalDate(jobcard?.job_date) : null;
  const workEndDate = jobcard?.job_end_date ? getLocalDate(jobcard?.job_end_date) : null;

  const checkInDays = getDuration(checkInFormattedDate, checkOutFormattedDate || new Date());
  const maintenanceDays = getDuration(workStartDate, workEndDate || new Date());

  const isCheckInDone = Boolean(checkInFormattedDate && isPast(checkInFormattedDate));
  const isCheckOutDone = Boolean(checkOutFormattedDate && isPast(new Date(checkOutFormattedDate)));

  const data = [
    {
      label: 'Booking approval',
      value: approved_by ? format(approved_by.timestamp, DEFAULT_USER_DATE_TIME_FORMAT) : undefined,
      valueSec: approved_by ? `Approved by ${approved_by.user}` : undefined,
      icon: <SuccessIcon />,
      isFinished: Boolean(approved_by) || isCheckInDone,
    },
    {
      label: 'Check-in / Inspection',
      value: checkInFormattedDate ? format(checkInFormattedDate, DEFAULT_USER_DATE_TIME_FORMAT) : '',
      icon: <CheckInIcon />,
      isFinished: isCheckInDone,
      editLink: checkInFormattedDate && !isCheckInDone ? LINKS.plannerCheckIn(bookingId) : undefined,
    },
    {
      label: 'Job start',
      value: workStartDate ? format(workStartDate, DEFAULT_USER_DATE_TIME_FORMAT) : '',
      icon: <WrenchScrewIcon />,
      isFinished: Boolean(workStartDate && isPast(new Date(workStartDate))),
    },
    {
      label: 'Job end',
      value: workEndDate ? format(workEndDate, DEFAULT_USER_DATE_TIME_FORMAT) : '',
      icon: <WrenchScrewIcon />,
      isFinished: Boolean(workEndDate && isPast(new Date(workEndDate))),
    },
    {
      label: 'Check-out',
      value: checkOutFormattedDate ? format(checkOutFormattedDate, DEFAULT_USER_DATE_TIME_FORMAT) : '',
      icon: <CheckOutIcon />,
      isFinished: isCheckOutDone,
      editLink: checkOutFormattedDate && !isCheckOutDone ? LINKS.plannerCheckOut(bookingId) : undefined,
    },
  ];

  return (
    <Box title='Maintenance timeline' icon={<WrenchPlannerIcon />} width='quarter'>
      <div className={css.container}>
        <div className={css.counters}>
          <div className={css.counter}>
            {checkInDays ? (
              <>
                <div className={css.digit}>{checkInDays}</div>
                <div className={css.text}>days at workshop</div>
              </>
            ) : (
              <div className={css.text}>Check-in has not been completed yet</div>
            )}
          </div>
          <div className={css.counter}>
            {maintenanceDays ? (
              <>
                <div className={css.digit}>{maintenanceDays}</div>
                <div className={css.text}>days under maintenance</div>
              </>
            ) : (
              <div className={css.text}>Maintenance has not started yet</div>
            )}
          </div>
        </div>
        <div>
          {data.map((props, idx) => (
            <PartTime {...props} key={idx} />
          ))}
        </div>
      </div>
    </Box>
  );
};

export default TimeLineBlock;
