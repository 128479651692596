import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  DefaultField,
  Dropdown,
  Empty,
  Main,
  NumberField,
  Page,
  Table,
  TablePagination,
  Text,
  TextArea,
  TextField,
  Tip,
  TotalCostsBox,
} from '@components';
import {
  ArrowLeftIcon,
  CloseRoundIcon,
  CompletedIcon,
  DocIcon,
  EditIcon,
  PlusIcon,
  SuccessIcon,
  SyncIcon,
  TrashIcon,
} from '@assets/svg';
import { DEFAULT_SERVER_DATE_FORMAT, DEFAULT_USER_MONTH_FORMAT, URLS } from '@common/constants';
import {
  IDraftStatement,
  IDropdownOption,
  IFocalPoint,
  IJobcard,
  IJobcardOptions,
  IJobcardPending,
  IStatementOptions,
  IStatementOtherCost,
  IStatementOtherCostPostData,
  JobcardSyncData,
  Nullable,
  PaginatedResponse,
  User,
} from '@common/interfaces';
import { useMutation, useQuery } from 'react-query';
import { useFacilityContext, useRepository } from '@context';
import { ColumnType } from 'antd/lib/table';
import { OptionProps } from 'react-select';
import { addMonths, format, startOfMonth, startOfYear } from 'date-fns';
import { formatMoneyStringToNumber, formatToMoneyString } from '@common/utils';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { useDownloadFile } from '@common/hooks';
import * as Yup from 'yup';
import { Form, FormState, useFormState } from 'informed';
import { v4 as uuid } from 'uuid';
import css from './styles.module.scss';

const DEFAULT_PAGE_SIZE = 10;

interface ICustomerOption extends IDropdownOption {
  jobcards: number;
}

interface AddOtherCostMutateData {
  payload: IStatementOtherCostPostData;
  statementId: string;
}

interface IOtherCostForm {
  idx: number;
  item: Partial<IStatementOtherCost>;
  setItems: Dispatch<SetStateAction<Partial<IStatementOtherCost>[]>>;
}

const generateMonthArray = (startDate: Date, endDate: Date): IDropdownOption[] => {
  const months = [];
  let currentDate = startOfMonth(startDate);

  while (currentDate <= endDate) {
    months.push({
      label: format(currentDate, DEFAULT_USER_MONTH_FORMAT),
      value: format(currentDate, DEFAULT_SERVER_DATE_FORMAT),
    });
    currentDate = addMonths(currentDate, 1);
  }

  return months;
};

const periodOptions = generateMonthArray(startOfYear(new Date()), new Date());

const CustomerOption = ({ innerProps, data }: OptionProps) => {
  const { label, jobcards } = data as ICustomerOption;

  return (
    <div className='rsf__option' {...innerProps}>
      {label} - {jobcards}{' '}
      <span className='rsf__option-note'>pending job card{jobcards > 1 ? 's' : ''} to be invoiced</span>
    </div>
  );
};

const yupSchema = Yup.object().shape({
  description: Yup.string().required(),
  cost: Yup.string().required(),
});

const OtherCostForm: FC<IOtherCostForm> = ({ idx, item, setItems }) => {
  const { uuid, description, cost } = item as IStatementOtherCost;

  const [isEdit, setIsEdit] = useState(true);

  const onSubmit = (data: FormState) => {
    setItems((prevArray) => [...prevArray].map((item) => (item.uuid === uuid ? { ...item, ...data.values } : item)));
    setIsEdit(false);
  };

  const discardItem = () => {
    setIsEdit(false);
    if (!description && !cost) {
      setItems((prevArray) => [...prevArray].filter((item) => item.uuid !== uuid));
    }
  };

  const deleteItem = () => {
    setItems((prevArray) => [...prevArray].filter((item) => item.uuid !== uuid));
  };

  const ButtonConfirm = () => {
    const { values } = useFormState();
    const { cost, description } = values as unknown as Partial<IStatementOtherCost>;
    const isNotValid = cost === undefined || !description;

    return (
      <Tip isVisible={isNotValid} text='All fields should be filled.'>
        <Button
          text='Confirm'
          iconL={<CompletedIcon />}
          type='submit'
          variant='text'
          className={css.buttonSuccess}
          disabled={isNotValid}
        />
      </Tip>
    );
  };

  return (
    <Form
      yupSchema={yupSchema}
      initialValues={item as unknown as Record<string, unknown>}
      className={`${css.row} ${isEdit ? css.editable : ''}`}
      onSubmit={onSubmit}
      key={isEdit.toString()}
    >
      <div className={css.td}>{idx + 1}</div>
      <div className={css.td}>{isEdit ? <TextField name='description' /> : description}</div>
      <div className={css.td}>{isEdit ? <NumberField name='cost' allowDecimal /> : formatToMoneyString(cost)}</div>
      <div className={css.td}>
        <div className={css.actions}>
          {isEdit ? (
            <>
              <Button text='Discard' iconL={<CloseRoundIcon />} onClick={discardItem} variant='text' />
              <ButtonConfirm />
            </>
          ) : (
            <>
              <Button
                text='Edit'
                iconL={<EditIcon />}
                onClick={() => setIsEdit(true)}
                variant='text'
                className={css.buttonEdit}
              />
              <Button text='Delete' iconL={<TrashIcon />} onClick={deleteItem} variant='text' />
            </>
          )}
        </div>
      </div>
    </Form>
  );
};

const GenerateStatementPage: FC = () => {
  const navigate = useNavigate();
  const { facilityId, facility } = useFacilityContext();
  const { jobcardRepository, globalRepository, facilityRepository, statementRepository } = useRepository();

  const { country_name, country } = facility;

  const [customer, setCustomer] = useState<Nullable<ICustomerOption>>(null);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [workshopFilter, setWorkshopFilter] = useState<Nullable<IDropdownOption>>(null);
  const [periodFilter, setPeriodFilter] = useState<Nullable<IDropdownOption>>(null);

  const [selectedJobcards, setSelectedJobcards] = useState<IJobcard[]>([]);

  const [otherItems, setOtherItems] = useState<Partial<IStatementOtherCost>[]>([]);
  const [workshopMarginValue, setWorkshopMarginValue] = useState(0);
  const [mcrMarginIncluded, setMcrMarginIncluded] = useState(true);
  const [approver, setApprover] = useState<Nullable<IDropdownOption>>(null);
  const [remarks, setRemarks] = useState('');
  const [slaNumber, setSlaNumber] = useState('');

  const { data: user, isLoading: userLoading } = useQuery<User>('user', () => globalRepository.getCurrentUser());

  const { data: focalPoints, isLoading: isFocalPointsLoading } = useQuery<PaginatedResponse<IFocalPoint>>(
    'focal-points',
    () => facilityRepository.getFocalPoints(facilityId)
  );

  const { data: jobcardsPending, isLoading: isJobcardsPendingLoading } = useQuery<IJobcardPending[]>(
    ['jobcards-pending', country],
    () => jobcardRepository.getJobcardsPending(facilityId, { by_country: country?.iso_code || '' }),
    {
      enabled: Boolean(country),
    }
  );

  const { data: jobcardOptions, isLoading: isJobcardOptionsLoading } = useQuery<IJobcardOptions>(
    ['jobcards-options', customer, country],
    () =>
      jobcardRepository.getJobcardsOptions(facilityId, {
        by_country: country?.iso_code || '',
        customer: customer?.value || '',
      }),
    {
      enabled: Boolean(customer) && Boolean(country),
    }
  );

  const { data: statementOptions, isLoading: isOptionsLoading } = useQuery<IStatementOptions>('statement-options', () =>
    statementRepository.getOptions(facilityId)
  );

  const { data: jobcards, isLoading: isJobcardsLoading } = useQuery<PaginatedResponse<IJobcard>>(
    ['jobcards', customer, periodFilter, workshopFilter],
    () =>
      jobcardRepository.getJobcards(facilityId, {
        limit: pageSize,
        offset: (page - 1) * 10,
        customer: customer?.value,
        reference_period: periodFilter?.value,
        workshop: workshopFilter?.value,
        is_pending: true,
        by_country: country?.iso_code || '',
      }),
    {
      enabled: Boolean(customer),
    }
  );

  const { mutate: generateStatementPdf, isLoading: isGenerationPdfInProgress } = useMutation(
    (statementId: string) => statementRepository.generateStatementPdf(facilityId, statementId),
    {
      onSuccess: ({ pdf, invoice_number }) => {
        toast.success('Statement was created successfully');
        useDownloadFile(pdf, `${invoice_number}.pdf`);
        setTimeout(() => navigate(URLS.DOCUMENTS), 1000);
      },
    }
  );

  const { mutate: addOtherCost, isLoading: isAddingOtherCostInProgress } = useMutation((data: AddOtherCostMutateData) =>
    statementRepository.addOtherCost(facilityId, data.statementId, data.payload)
  );

  const { mutate: createStatement, isLoading: isCreationInProgress } = useMutation(
    (data: IDraftStatement) => statementRepository.createStatement(facilityId, data),
    {
      onSuccess: ({ uuid }) => {
        const promises = otherItems
          .filter(({ cost, description }) => cost && description)
          .map((item) => {
            const { description, cost } = item as IStatementOtherCostPostData;
            const payload = {
              description,
              cost: formatMoneyStringToNumber(cost),
            };
            return addOtherCost({ payload, statementId: uuid });
          });
        Promise.all(promises).then(() => generateStatementPdf(uuid));
      },
    }
  );

  const { mutate: syncJobcardsData, isLoading: isJobcardSyncInProgress } = useMutation(
    (params: JobcardSyncData) => jobcardRepository.syncJobcardsData(facilityId, params),
    {
      onSuccess: ({ detail }) => {
        toast.success(detail);
      },
    }
  );

  const columns: ColumnType<IJobcard>[] = [
    {
      dataIndex: 'pk',
      key: 'pk',
      render: (_, item) => (
        <Checkbox
          checked={selectedJobcards.some((jobcard) => jobcard.pk === item.pk)}
          onChange={(checked) => {
            if (checked) {
              setSelectedJobcards((prevArray) => [...prevArray, item]);
            } else {
              setSelectedJobcards((prevArray) => [...prevArray].filter((jobcard) => jobcard.pk !== item.pk));
            }
          }}
        />
      ),
      title: () => {
        return (
          <Checkbox
            checked={Boolean(jobcards?.results?.length) && jobcards?.results.length === selectedJobcards.length}
            onChange={(checked) => {
              if (checked) {
                setSelectedJobcards(() => [...(jobcards?.results || [])]);
              } else {
                setSelectedJobcards([]);
              }
            }}
          />
        );
      },
    },
    {
      dataIndex: 'number',
      key: 'number',
      title: '#',
      render: (_1, _2, idx) => idx + 1,
    },
    {
      dataIndex: 'job_number',
      key: 'job_number',
      title: 'Job card',
    },
    {
      dataIndex: 'workshop',
      key: 'workshop',
      title: 'Workshop',
      render: (workshop) => workshop || 'n.d.',
    },
    {
      dataIndex: 'job_date',
      key: 'job_date',
      title: 'Reference period',
      render: (job_date) => (job_date ? format(job_date, DEFAULT_USER_MONTH_FORMAT) : '-'),
    },
    {
      dataIndex: 'related_asset',
      key: 'related_asset',
      title: 'Related asset',
      render: (_, { plate_number, make, model }) => `${plate_number || 'n.d.'} / ${make || 'n.d.'} ${model || 'n.d.'}`,
    },
    {
      dataIndex: 'total_cost',
      key: 'total_cost',
      title: 'Total cost (USD)',
      render: (total_cost) => formatToMoneyString(total_cost),
    },
  ];

  const clearPagination = () => {
    setPage(1);
    setPageSize(DEFAULT_PAGE_SIZE);
  };

  const clearFilters = () => {
    setPeriodFilter(null);
    setWorkshopFilter(null);
  };

  const clearAllParams = () => {
    clearPagination();
    clearFilters();
  };

  const handleGenerate = () => {
    createStatement({
      jobcards: selectedJobcards.map(({ pk }) => pk),
      customer: customer?.value || '',
      pdf_remarks: remarks,
      sla_ref: slaNumber,
      approved_by: approver?.value || '',
      workshop_margin_percentage: workshopMarginValue,
      reference_period: periodFilter?.value || '',
      is_mcr_needed: mcrMarginIncluded,
    });
  };

  useEffect(() => {
    setSelectedJobcards([]);
  }, [customer, periodFilter, workshopFilter]);

  const customerOptions =
    jobcardsPending?.map(({ customer, jobcards }) => ({
      label: customer,
      value: customer,
      jobcards,
    })) || [];

  const approversOptions = focalPoints
    ? focalPoints.results.map(({ uuid, first_name, last_name }) => ({
        label: `${first_name} ${last_name}`,
        value: uuid,
      }))
    : [];

  const mcrMarginValue = statementOptions?.variables.mcr_fee_percentage || 0;

  const totalOtherCosts = otherItems.reduce((acc, { cost = 0 }) => {
    return acc + formatMoneyStringToNumber(cost);
  }, 0);

  const totalLineItems =
    selectedJobcards.reduce((acc, { total_cost = 0 }) => {
      return acc + total_cost;
    }, 0) + totalOtherCosts;

  const isGeneratingPossible = selectedJobcards.length > 0 && approver && slaNumber && periodFilter;

  const isFiltered = periodFilter || workshopFilter;

  const addNewItem = () => setOtherItems((prevArray) => [...prevArray, { uuid: `draft${uuid()}` }]);

  const loading =
    isJobcardsPendingLoading ||
    isJobcardOptionsLoading ||
    isOptionsLoading ||
    userLoading ||
    isJobcardsLoading ||
    isGenerationPdfInProgress ||
    isFocalPointsLoading ||
    isAddingOtherCostInProgress ||
    isCreationInProgress;

  return (
    <Main loading={loading}>
      <Page>
        <Button text='Back to all documents' variant='text' iconL={<ArrowLeftIcon />} link={URLS.DOCUMENTS} />
        <div className={css.title}>New statement</div>
        <div className={css.note}>Easily generate periodic work statements for your clients</div>
        <div className={css.top}>
          <Dropdown
            className={css.customerDropdown}
            value={customer}
            options={customerOptions}
            onChange={setCustomer}
            placeholder='Choose customer'
            Option={CustomerOption}
          />
          {country ? (
            <Button
              text='Sync with FMS'
              iconR={<SyncIcon />}
              className={css.button}
              variant='text'
              disabled={isJobcardSyncInProgress}
              onClick={() => syncJobcardsData({ country_codes: [country.iso_code] })}
            />
          ) : null}
          {customer ? (
            <div className={css.address}>
              <div className={css.label}>Customer details</div>
              {customer.label}, {country_name}
            </div>
          ) : null}
        </div>
        {customer ? (
          <>
            <div className={css.tableTop}>
              <div className={css.subtitle}>Select the job cards and period to be invoiced</div>
              <div className={css.filters}>
                {isFiltered ? <Button text='Reset filters' variant='text' onClick={() => clearAllParams()} /> : null}
                <Dropdown
                  className={css.workshopDropdown}
                  value={workshopFilter}
                  options={jobcardOptions?.workshops}
                  onChange={setWorkshopFilter}
                  placeholder='Filter by workshop'
                />
                <Dropdown
                  value={periodFilter}
                  options={periodOptions}
                  onChange={setPeriodFilter}
                  placeholder='Filter by period'
                />
              </div>
            </div>
            <Table className={css.table} columns={columns} data={jobcards?.results} rowKey='pk' />
            <TablePagination
              page={page}
              onChangePage={setPage}
              totalCount={jobcards?.count}
              onPageSizeChange={setPageSize}
              pageSize={pageSize}
            />
            <div className={css.otherSubtitle}>Other costs breakdown</div>
            <Button
              text='Add new other cost'
              variant='text'
              iconR={<PlusIcon />}
              onClick={addNewItem}
              className={css.buttonAdd}
            />
            <div className={css.tableWrapper}>
              <div className={css.otherCostTable}>
                <div className={css.thead}>
                  <div className={css.th}>#</div>
                  <div className={css.th}>Description</div>
                  <div className={css.th}>Total parts cost (USD)</div>
                  <div className={css.th}>Actions</div>
                </div>
                <div className={css.tbody}>
                  {otherItems.length > 0 ? (
                    otherItems.map((item, idx) => (
                      <OtherCostForm item={item} idx={idx} key={item.uuid} setItems={setOtherItems} />
                    ))
                  ) : (
                    <Empty text='No line items' />
                  )}
                </div>
              </div>
            </div>
            <div className={css.tfoot}>
              <div className={css.td}>Total</div>
              <div className={css.td} />
              <div className={css.td}>{formatToMoneyString(totalOtherCosts)}</div>
              <div className={css.td} />
            </div>
          </>
        ) : (
          <div className={css.empty}>
            <DocIcon />
            Select a customer and search to display their associated jobcards here.
          </div>
        )}
        <div className={css.content}>
          <div className={css.pfiInfo}>
            <div className={css.field}>
              <div className={css.label}>SLA ref</div>
              <Text value={slaNumber} onChange={setSlaNumber} />
            </div>
            <div className={css.field}>
              <div className={css.label}>Prepared by</div>
              <DefaultField text={user?.full_name} />
            </div>
            <div className={css.field}>
              <div className={css.label}>Approved by</div>
              <Dropdown value={approver} options={approversOptions} onChange={setApprover} />
            </div>
            <div className={css.field}>
              <div className={css.label}>
                PDF remarks <span>(optional)</span>
              </div>
              <TextArea value={remarks} onChange={setRemarks} />
            </div>
          </div>
          <TotalCostsBox
            mcrMarginValue={mcrMarginValue}
            totalLineItems={totalLineItems}
            setWorkshopMarginValue={setWorkshopMarginValue}
            workshopMarginValue={workshopMarginValue}
            mcrMarginIncluded={mcrMarginIncluded}
            setMcrMarginIncluded={setMcrMarginIncluded}
          />
        </div>
        <Tip
          isVisible={!isGeneratingPossible}
          text='At least one selected jobcard, period, approver and SLA ref should be added'
        >
          <Button
            text='Generate statement'
            iconR={<SuccessIcon />}
            variant='forest'
            onClick={handleGenerate}
            disabled={!isGeneratingPossible}
          />
        </Tip>
      </Page>
    </Main>
  );
};

export default GenerateStatementPage;
