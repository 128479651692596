import React, { FC, useState } from 'react';
import { useAuth } from '@unbooking/ui-auth';
import { useLocation } from 'react-router';
import { useQuery } from 'react-query';
import { CMSNavigationResponse, User, UserType } from '@common/interfaces';
import { useFacilityContext, useRepository } from '@context';
import { Button } from '@components';
import { Link } from 'react-router-dom';
import { URLS } from '@common/constants';
import { HomeIcon, LockIcon, LogoWFPIcon, MenuIcon } from '@assets/svg';
import Navigation from '../Navigation';
import { removeNonSSOSession } from '@common/utils';
import { useUserType } from '@common/hooks';
import NavigationMobile from '@components/NavigationMobile';
import css from './styles.module.scss';

const Header: FC = () => {
  const { managedFacilities } = useFacilityContext();
  const location = useLocation();
  const { userType } = useUserType();
  const { globalRepository, cmsRepository } = useRepository();
  const { logout } = useAuth();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const isUserSSO = userType === UserType.SSO;
  const isDO = location.pathname.includes(URLS.DO);
  const headerType = isDO ? 'do-header' : 'default-header';

  const { data: user } = useQuery<User>('user', () => globalRepository.getCurrentUser());

  const { data: header } = useQuery<CMSNavigationResponse>(['cms-navigation', headerType], () =>
    cmsRepository.getElement(headerType)
  );

  const logoutHandler = () => {
    isUserSSO && logout?.();
    removeNonSSOSession();
  };

  const closeMenu = () => setMobileMenuOpen(false);

  const logoUrl = header?.logo?.meta.download_url;

  const userElement = user ? (
    <div className={css.user}>
      <LogoWFPIcon className={css.logoWfp} />
      <div className={css.content}>
        <div className={css.name}>{`Welcome, ${user.first_name} ${user.last_name}`}</div>
        <div>{user.organisation?.short_name}</div>
        <div className={css.actions}>
          <Button className={css.logout} text='Log out' variant='text' onClick={logoutHandler} />
        </div>
      </div>
    </div>
  ) : null;

  const mainHeaderElement = isDO ? (
    <>
      <Link to={URLS.DO} className={css.logo}>
        {logoUrl ? <img src={logoUrl} alt='Digital Office' /> : null}
      </Link>
      <Link to={URLS.ROOT} className={css.button}>
        <HomeIcon />
        <span>
          Go to the <br />
          <b>Workshop Platform</b>
        </span>
      </Link>
    </>
  ) : (
    <>
      <Link to={URLS.ROOT} className={css.logo}>
        {logoUrl ? <img src={logoUrl} alt='Maintenance Services' /> : null}
      </Link>
      <Link to={managedFacilities.length > 0 ? URLS.DO : URLS.FORBIDDEN} className={css.button}>
        <LockIcon />
        <span>
          Digital
          <br />
          <b>Office</b>
        </span>
      </Link>
    </>
  );

  const mobileUserElement = user ? (
    <div className={css.user}>
      <div className={css.name}>{`Welcome, ${user.first_name} ${user.last_name}`}</div>
      <div>{user.organisation?.short_name}</div>
      <Button className={css.logout} text='Log out' variant='text' onClick={logoutHandler} />
    </div>
  ) : null;

  const mobileMainHeaderElement = isDO ? (
    <Link to={URLS.ROOT} className={css.button} onClick={closeMenu}>
      <HomeIcon />
      <span>
        Go to the <br />
        <b>Workshop Platform</b>
      </span>
    </Link>
  ) : (
    <Link to={managedFacilities.length > 0 ? URLS.DO : URLS.FORBIDDEN} className={css.button} onClick={closeMenu}>
      <LockIcon />
      <span>
        Digital
        <br />
        <b>Office</b>
      </span>
    </Link>
  );

  return (
    <div className={css.wrapper}>
      <header className={css.header}>
        <Button onClick={() => setMobileMenuOpen(!mobileMenuOpen)} iconL={<MenuIcon />} className={css.menuButton} />
        {mainHeaderElement}
        {userElement}
      </header>
      <NavigationMobile navigation={header?.navigation || []} isActive={mobileMenuOpen} closeMenu={closeMenu}>
        <div className={css.mobileMenuInfo}>
          {mobileMainHeaderElement}
          {mobileUserElement}
        </div>
      </NavigationMobile>
      <Navigation navigation={header?.navigation || []} />
    </div>
  );
};

export default Header;
