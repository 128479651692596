import { FC, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { StatusDropdown, Button, Chat, Main, Page, StatusLabel } from '@components';
import { ArrowLeftIcon, CommentIcon, UserIcon, WarningIcon, WrenchCarIcon } from '@assets/svg';
import { URLS } from '@common/constants';
import {
  IAssetStatus,
  IAssetStatusValue,
  IBookingDetails,
  IBookingDetailsPostData,
  IBookingStatus,
  IComment,
  ICommentDraft,
  IFocalPoint,
  JobcardStatus,
  PaginatedResponse,
} from '@common/interfaces';
import { useMutation, useQuery } from 'react-query';
import { useFacilityContext, useRepository } from '@context';
import { useParams } from 'react-router-dom';
import { ActionsBlock, DetailsBlock, DocsBlock, JobCardBlock, StatusHistoryModal, TimeLineBlock } from './components';
import { toast } from 'react-toastify';
import Box from './components/Box';
import { IStatusLabelVariant } from '@components/StatusLabel';
import { useInterval } from '@common/hooks';

import css from './styles.module.scss';

interface IAssetStatusBox {
  status: IAssetStatus;
}

const AssetStatusBox: FC<IAssetStatusBox> = ({ status }) => {
  const { label, value, support_request_number } = status;

  let tip = null;
  let variant = '';

  switch (value) {
    case IAssetStatusValue.EXIST_ON_FMS:
      variant = 'green';
      break;
    case IAssetStatusValue.NOT_EXIST_ON_FMS:
      variant = 'grey';
      tip = <>Click on Create jobcard to automatically request the creation of the asset.</>;
      break;
    case IAssetStatusValue.CREATION_ONGOING:
      variant = 'orange';
      tip = (
        <>
          Click{' '}
          <a href={process.env.REACT_APP_GF_SUPPORT_URL} target='_blank' rel='noreferrer'>
            here
          </a>{' '}
          to view your asset creation request ticket <b>{support_request_number}</b> on Global Fleet
        </>
      );
      break;
    default:
      break;
  }

  return <StatusLabel variant={variant as IStatusLabelVariant} text={label} tip={tip} />;
};

const BookingDetailsPage: FC = () => {
  const { id: bookingId = '' } = useParams();
  const { bookingRepository, facilityRepository } = useRepository();
  const { facilityId } = useFacilityContext();

  const [statusHistoryModalOpen, setStatusHistoryModalOpen] = useState(false);

  const {
    data: bookingDetails,
    isLoading: isDetailsLoading,
    refetch,
  } = useQuery<IBookingDetails>(`request-${bookingId}`, () =>
    bookingRepository.getBookingDetails(facilityId, bookingId)
  );

  const { data: focalPoints, isLoading: isFocalPointsLoading } = useQuery<PaginatedResponse<IFocalPoint>>(
    'focal-points',
    () => facilityRepository.getFocalPoints(facilityId)
  );

  const {
    data: comments,
    isLoading: isCommentsLoading,
    refetch: refetchComments,
  } = useQuery<PaginatedResponse<IComment>>('comments', () => bookingRepository.getComments(facilityId, bookingId));

  const { mutate: sendComment, isLoading: isSendingCommentInProgress } = useMutation(
    (data: ICommentDraft) => bookingRepository.sendComment(facilityId, bookingId, data),
    {
      onSuccess: () => {
        toast.success('Comment was added successfully');
        refetchComments();
      },
    }
  );

  const { mutate: updateBooking, isLoading: isUpdatingInProgress } = useMutation(
    (data: IBookingDetailsPostData) => bookingRepository.updateBooking(facilityId, bookingId, data),
    {
      onSuccess: (booking) => {
        toast.success(`Booking ${booking.request_number} was updated`);
        refetch();
      },
    }
  );

  const isLoading =
    isCommentsLoading || isFocalPointsLoading || isDetailsLoading || isSendingCommentInProgress || isUpdatingInProgress;

  const isPfiNeeded = bookingDetails && bookingDetails.is_quotation_required && !bookingDetails.pfi;

  const isJobcardCreationAvailable =
    bookingDetails &&
    (bookingDetails.state === IBookingStatus.INSPECTED ||
      bookingDetails.state === IBookingStatus.PFI_IN_APPROVAL ||
      bookingDetails.state === IBookingStatus.PFI_SENT);

  useInterval(() => {
    if (
      bookingDetails &&
      ((bookingDetails.fms_jobcard_link_request_sent && !bookingDetails.jobcard) || bookingDetails.fms_sync_in_progress)
    )
      refetch();
  }, 5000);

  return (
    <Main loading={isLoading}>
      <Page>
        {bookingDetails ? (
          <>
            <Button text='Back to bookings' variant='text' iconL={<ArrowLeftIcon />} link={URLS.BOOKINGS} />
            <div className={css.top}>
              <div>
                <div className={css.titleLine}>
                  <div className={css.title}>
                    Booking <div className={css.number}>{bookingDetails.request_number}</div>
                  </div>
                  <StatusDropdown
                    allowedTransitions={bookingDetails.allowed_transitions}
                    status={bookingDetails.state}
                    updateBooking={updateBooking}
                    bookingDetails={bookingDetails}
                    requestor={`${bookingDetails.requestor_details.name} ${bookingDetails.requestor_details.surname}`}
                  />
                  {bookingDetails.transitions.length > 0 ? (
                    <Button text='View change history' variant='text' onClick={() => setStatusHistoryModalOpen(true)} />
                  ) : null}
                  {bookingDetails.jobcard?.job_status === JobcardStatus.WAITING_FOR_PARTS ? (
                    <div className={css.jobStatus}>
                      <WarningIcon />
                      Waiting for parts
                    </div>
                  ) : null}
                </div>
                <div className={css.info}>
                  <div>
                    <UserIcon />
                    {`${bookingDetails.requestor_details.name} ${bookingDetails.requestor_details.surname}, ${bookingDetails.requesting_agency}`}
                  </div>
                  <div>
                    <WrenchCarIcon />
                    {`${bookingDetails.plate_number}, ${bookingDetails.make?.label || ''} ${
                      bookingDetails.model?.label || ''
                    }`}
                  </div>
                  {isJobcardCreationAvailable ? <AssetStatusBox status={bookingDetails.asset_status} /> : null}
                  {isPfiNeeded ? <StatusLabel variant='blue' text='PFI needed' /> : null}
                </div>
              </div>
              <ActionsBlock bookingDetails={bookingDetails} refetch={refetch} updateBooking={updateBooking} />
            </div>
            <section className={css.container}>
              <DetailsBlock bookingDetails={bookingDetails} updateBooking={updateBooking} />
              <TimeLineBlock bookingDetails={bookingDetails} />
              <div className={css.boxWrapper}>
                <JobCardBlock bookingDetails={bookingDetails} refetch={refetch} />
                <DocsBlock bookingDetails={bookingDetails} />
              </div>
              <Box title='Comments' icon={<CommentIcon />} width='half'>
                <Chat
                  defaultComments={
                    bookingDetails.jobcard?.remarks
                      ? [{ body: bookingDetails.jobcard.remarks, author: 'FMS Remark', uuid: uuid(), label: 'FMS' }]
                      : undefined
                  }
                  comments={comments?.results || []}
                  focalPoints={focalPoints?.results}
                  sendComment={(comment: string) => sendComment({ body: comment })}
                />
              </Box>
            </section>
          </>
        ) : null}
        {statusHistoryModalOpen && bookingDetails && (
          <StatusHistoryModal
            transitions={bookingDetails.transitions}
            closeModal={() => setStatusHistoryModalOpen(false)}
          />
        )}
      </Page>
    </Main>
  );
};

export default BookingDetailsPage;
